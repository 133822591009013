import { Injectable } from '@angular/core';
import { IFindPoliciesByCriteriaRequest } from '@shared-global/interfaces/policies/interfaces/find-policies-by-criteria-request.interface';
import { IPolicy } from '@shared-global/interfaces/policies/interfaces/policy.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class PolicyService extends AbstractCrudService<
  IPolicy,
  IFindPoliciesByCriteriaRequest
> {
  protected override feature = 'policies';
  protected override endPoint = `/policies/`;
}
